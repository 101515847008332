import React from 'react';
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet';

const EnglishBeaconID = 'c4129560-61d2-4f75-9667-aa1fce399ad8';
const SpanishBeaconID = '006c31ef-6c7a-4aaa-af80-2fa6616293ce';

const HelpScoutTagWrapper = ({ children, includeHelpScoutTag }) => {
  const { i18n } = useTranslation();

  var beaconID = EnglishBeaconID;
  if (i18n.language === 'es') {
    beaconID = SpanishBeaconID;
  }

  return (
    <>
      {includeHelpScoutTag && (
        <Helmet>
          <script type="text/javascript">
            {`!function (e, t, n) {function a() { var e = t.getElementsByTagName("script")[0], n = t.createElement("script"); n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e) } if (e.Beacon = n = function (t, n, a) {e.Beacon.readyQueue.push({ method: t, options: n, data: a })}, n.readyQueue = [], "complete" === t.readyState) return a(); e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1) }(window, document, window.Beacon || function () { });`}
          </script>
          <script type="text/javascript">
            {`window.Beacon('init', '` + beaconID + `')`}
          </script>
        </Helmet>
      )}
      {children}
    </>
  );
};

export default HelpScoutTagWrapper;
