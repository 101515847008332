import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import * as moment from 'moment-timezone'
import { Formik, Field, Form } from "formik";

import axiosInstance from '../actions/index';

let ConfirmedAppointment = ({ event_id, event_type_id }) => {
    const [event, setEvent] = useState(null);

    const [cancelEvent, setCancelEvent] = useState(null)
    const [eventCancelled, setEventCancelled] = useState(false)

    const { t, i18n } = useTranslation();

    useEffect(() => {
        fetchEvent(event_id, event_type_id).then(e => setEvent(e))
    }, [])

    let cancelAppointment = (event_id, event_type_id) => {
        setCancelEvent({
            "event_id": event_id,
            "event_type_id": event_type_id
        })
    }

    let handleCancelAppointment = (values) => {
        const resp = axiosInstance.post('/event/cancel',
            {
                "guest_email": values.email,
                "event_id": cancelEvent.event_id,
                "event_type_id": cancelEvent.event_type_id
            }).then(resp => setEventCancelled(true))
    }

    // Function to format date to YYYYMMDDTHHmmSSZ using moment-timezone
    const formatDateToRFC3339 = (date) => {
        return moment(date).utc().format('YYYYMMDDTHHmmSS[Z]');
    };

    if (!event) {
        return <div>{t('common.loading')}</div>
    }

    const handleAddToCalendar = () => {
        const startDate = moment.tz(event.date, event.guest_timezone);
        const endDate = startDate.clone().add(event.duration_minutes, 'minutes')
        const formattedStartDate = formatDateToRFC3339(startDate);
        const formattedEndDate = formatDateToRFC3339(endDate);

        let event_name = ""
        if (i18n.language == "es") {
            event_name = "evento " + event.name + " con " + event.host_name;
        } else {
            event_name = event_name = event.host_name + " " + event.name + " meeting";
        }

        let location = "";
        if (event.meeting_location != null && event.meeting_location.length > 0) {
            switch (event.meeting_location[0].key) {
                case "in_person":
                    location = event.meeting_location[0].value;
                    break;
                case "google_meets" || "zoom":
                    location = t('component.confirmed_appointment.videocall_link')
                    break;
            }

        }

        let url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event_name)}&dates=${formattedStartDate}/${formattedEndDate}`;

        if (location != "") {
            url += `&location=${encodeURIComponent(location)}`
        }

        window.open(url, '_blank');
    };

    if (eventCancelled) {
        return (
            <div className="px-10 py-6">
                <div name="calendar_header" className="py-8 border-b border-gray-100">
                    <h5 className="text-gray-900 text-md m-5">{t('component.confirmed_appointment.cancelled')}</h5>
                </div>
                <EventData event={event} />
            </div>
        )
    }

    return (
        <div>
            <div name="calendar_header" className="py-8 border-b border-gray-100">
                <h5 className="text-gray-900 text-md m-5">{t('component.booking.confirmed')}</h5>
                <h2 className="text-gray-400 text-md m-5"> {t('component.booking.scheduled_with')} {event.host_name}.</h2>
            </div>
            <EventData event={event} />

            {/* add to calendar */}
            <button
                onClick={handleAddToCalendar}
                className='text-indigo-600'>
                {t('component.confirmed_appointment.add_to_google_calendar')}
            </button>

            {/* cancel appointment */}
            <div className="flex justify-center items-center pt-6">
                {!cancelEvent &&
                    <div>
                        <p className="text-gray-700 text-sm mx-5"> {t('component.booking.calendar_invitation_sent')}</p>
                        <p className="text-gray-700 text-sm mx-5"> {t('component.booking.cancell_if_cant_attend')}</p>
                        <div className="my-5">
                            <a
                                onClick={() => cancelAppointment(event.event_id, event.event_type_id)}
                                className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-gray-900 bg-white  cursor-pointer hover:text-indigo-600"
                            >
                                {t('component.confirmed_appointment.cancel_button')}
                            </a>
                        </div>
                    </div>
                }

                {cancelEvent &&
                    <div>
                        <div className="my-5">
                            <span className="text-gray-700 text-sm"> {t('component.confirmed_appointment.confirmation_email')}</span>
                        </div>
                        <div className="py-6 px-8">
                            <Formik initialValues={{ email: '' }} onSubmit={(values) => { handleCancelAppointment(values) }}>
                                <Form>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">{t('component.booking.emailField')}</label>
                                    <Field
                                        type="text"
                                        name="email"
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        label="Email"
                                    />
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="submit"
                                            className="rounded-md bg-indigo-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                            {t('component.confirmed_appointment.yes_cancel_button')}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setCancelEvent(null)}
                                            className="text-sm font-semibold leading-6 text-gray-900">
                                            {t('component.confirmed_appointment.no_cancel_button')}
                                        </button>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                }
            </div>

            {/* create your cronos calendar */}
            <div className="flex justify-center items-center mb-6">
                <h2 className="text-gray-500 text-md m-5">{t('component.confirmed_appointment.create_your_scheduling_page') + " 👉 "}
                    <a
                        href="https://cronoscal.com"
                        target="_blank" // Optional: opens in a new tab
                        rel="noopener noreferrer" // Security best practice
                        className='text-indigo-600 text-md'>
                        {t('common.here')}
                    </a>
                </h2>
            </div>
        </div>
    );
}
export default ConfirmedAppointment;

let EventData = ({ event }) => {
    const { t, i18n } = useTranslation();

    let location
    if (event.meeting_location && event.meeting_location.length > 0) {
        location = event.meeting_location[0]
    }
    return (
        <div className="flex justify-center">
            <ul className="p-6">
                <li>
                    <div className="flex">
                        <div className="w-6 h-6 mr-2 mt-1 text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                                <circle cx="8" cy="8" r="8" />
                            </svg>
                        </div>
                        <span className="block">{event.name}</span>
                    </div>
                </li>
                <li>
                    <Location meeting_location={location} />
                </li>
                <li>
                    <div className="flex">
                        <div className="w-6 h-6 mr-2 mt-1 text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                                <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                                <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                            </svg>
                        </div>
                        <span className="block">{moment.tz(event.date, event.guest_timezone).format('MMMM Do YYYY, h:mm a')}</span>
                    </div>
                </li>
                <li>
                    <div className="flex">
                        <div className="w-6 h-6 mr-2 mt-1 text-gray-400">
                            <svg data-id="details-item-icon" width="16" height="16" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" role="img">
                                <path d="M.5 5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 1 0-9 0Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M5 3.269V5l1.759 2.052" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </div>
                        <span className="">{event.duration_minutes} {t('common.min')}</span>
                    </div>
                </li>
                <li>
                    <div className="flex">
                        <div className="w-6 h-6 mr-2 mt-1 text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe-americas" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                            </svg>
                        </div>
                        <span className="block">{event.guest_timezone}</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}


let Location = ({ meeting_location }) => {
    const googleMeetsSVG = (<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48"><rect width="16" height="16" x="12" y="16" fill="#fff" transform="rotate(-90 20 24)"></rect><polygon fill="#1e88e5" points="3,17 3,31 8,32 13,31 13,17 8,16"></polygon><path fill="#4caf50" d="M37,24v14c0,1.657-1.343,3-3,3H13l-1-5l1-5h14v-7l5-1L37,24z"></path><path fill="#fbc02d" d="M37,10v14H27v-7H13l-1-5l1-5h21C35.657,7,37,8.343,37,10z"></path><path fill="#1565c0" d="M13,31v10H6c-1.657,0-3-1.343-3-3v-7H13z"></path><polygon fill="#e53935" points="13,7 13,17 3,17"></polygon><polygon fill="#2e7d32" points="38,24 37,32.45 27,24 37,15.55"></polygon><path fill="#4caf50" d="M46,10.11v27.78c0,0.84-0.98,1.31-1.63,0.78L37,32.45v-16.9l7.37-6.22C45.02,8.8,46,9.27,46,10.11z"></path></svg>)
    const googleMapsSVG = (<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48"><path fill="#48b564" d="M35.76,26.36h0.01c0,0-3.77,5.53-6.94,9.64c-2.74,3.55-3.54,6.59-3.77,8.06	C24.97,44.6,24.53,45,24,45s-0.97-0.4-1.06-0.94c-0.23-1.47-1.03-4.51-3.77-8.06c-0.42-0.55-0.85-1.12-1.28-1.7L28.24,22l8.33-9.88	C37.49,14.05,38,16.21,38,18.5C38,21.4,37.17,24.09,35.76,26.36z"></path><path fill="#fcc60e" d="M28.24,22L17.89,34.3c-2.82-3.78-5.66-7.94-5.66-7.94h0.01c-0.3-0.48-0.57-0.97-0.8-1.48L19.76,15	c-0.79,0.95-1.26,2.17-1.26,3.5c0,3.04,2.46,5.5,5.5,5.5C25.71,24,27.24,23.22,28.24,22z"></path><path fill="#2c85eb" d="M28.4,4.74l-8.57,10.18L13.27,9.2C15.83,6.02,19.69,4,24,4C25.54,4,27.02,4.26,28.4,4.74z"></path><path fill="#ed5748" d="M19.83,14.92L19.76,15l-8.32,9.88C10.52,22.95,10,20.79,10,18.5c0-3.54,1.23-6.79,3.27-9.3	L19.83,14.92z"></path><path fill="#5695f6" d="M28.24,22c0.79-0.95,1.26-2.17,1.26-3.5c0-3.04-2.46-5.5-5.5-5.5c-1.71,0-3.24,0.78-4.24,2L28.4,4.74	c3.59,1.22,6.53,3.91,8.17,7.38L28.24,22z"></path></svg>)

    if (!meeting_location) {
        return <></>
    }

    let iconSVG = "";
    let locationInfo = "";
    if (meeting_location.key == "in_person") {
        iconSVG = googleMapsSVG
        locationInfo = meeting_location.value
    } else if (meeting_location.key == "google_meets") {
        iconSVG = googleMeetsSVG
        locationInfo = "Google Meet"
    }

    return (
        <div className="flex">
            <div className="w-6 h-6 mr-2 mt-1 text-gray-400">
                {iconSVG}
            </div>
            <span className="block"> {locationInfo}</span>
        </div>
    )
}

let fetchEvent = async (eventID, eventTypeID) => {
    const resp = await axiosInstance.get('/event-type/' + eventTypeID + '/event/' + eventID)
    return resp.data;
}